export const NavbarLinks = [
    {
        title: "Home",
        path: "/",
    },
    {
        title: "Services",
        
    },
    {
        title: "Blog",
        path: "/blog",
    },
    {
        title:"Portfolio",
        path:"/portfolio",
    },
    {
        title:"About Us",
        path: "/aboutus",
    },
    {
        title:"Contact Us",
        path: "/contactus",
    },
   
]